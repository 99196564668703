import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider } from 'antd';
import { GlobalSuspense } from '@components';
let SuspendedApp = React.lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <GlobalSuspense>
      <ConfigProvider theme={{ token: { fontFamily: 'Poppins' } }}>
        <SuspendedApp />
      </ConfigProvider>
    </GlobalSuspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
